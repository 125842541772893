import styled from '../styled-components';
import { PrismDiv } from './PrismDiv';
export const SampleControls = styled.div`
  opacity: 0.4;
  transition: opacity 0.3s ease;
  text-align: right;
  &:focus-within {
    opacity: 1;
  }
  > button {
    background-color: transparent;
    border: 0;
    color: inherit;
    padding: 2px 10px;
    font-family: ${({
  theme
}) => theme.typography.fontFamily};
    font-size: ${({
  theme
}) => theme.typography.fontSize};
    line-height: ${({
  theme
}) => theme.typography.lineHeight};
    cursor: pointer;
    outline: 0;

    :hover,
    :focus {
      background: rgba(255, 255, 255, 0.1);
    }
  }
`;
export const SampleControlsWrap = styled.div`
  &:hover ${SampleControls} {
    opacity: 1;
  }
`;
export const StyledPre = styled(PrismDiv.withComponent('pre'))`
  font-family: ${props => props.theme.typography.code.fontFamily};
  font-size: ${props => props.theme.typography.code.fontSize};
  overflow-x: auto;
  margin: 0;

  white-space: ${({
  theme
}) => theme.typography.code.wrap ? 'pre-wrap' : 'pre'};
`;